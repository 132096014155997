import React from 'react'
import { Route, Routes, Outlet } from 'react-router-dom'
import App from './App/App'
import Login from 'screens/Login/Login'
import ForgotPassword from 'screens/ForgotPassword/ForgotPassword'
import ResetPassword from 'screens/ResetPassword/ResetPassword'
import Case from 'screens/Case/Case'
import ClassifyEmails from 'screens/ClassifyEmails/ClassifyEmails'
import Job from 'screens/Job/Job'
import Cases from 'screens/Cases/Cases'
import Clients from 'screens/Clients/Clients'
import Client from 'screens/Client/Client'
import Downloads from 'screens/Downloads/Downloads'
import Preferences from 'screens/Preferences/Preferences'
import Help from 'screens/Help/Help'
import Register from 'screens/Register/Register'
import NotFound from 'screens/NotFound/NotFound'
import AccountSetup from 'screens/AccountSetup/AccountSetup'
import DocumentReview from 'screens/DocumentReview/DocumentReview'
import Bates from 'screens/Bates/Bates'
import ClientPortal from 'screens/ClientPortal/ClientPortal'
import AddToClioFlow from 'screens/AddToClioFlow/AddToClioFlow'
import ClioIdentityCallback from 'screens/ClioIdentityCallback/ClioIdentityCallback'
import VerifyEmail from 'screens/VerifyEmail/VerifyEmail'
import ClioCustomAction from 'screens/ClioCustomAction/ClioCustomAction'
import SubscriptionPayment from 'screens/SubscriptionPayment/SubscriptionPayment'
import SubscriptionPaymentPreauth from 'screens/SubscriptionPaymentPreauth/SubscriptionPaymentPreauth'
import CaseSearch from 'screens/CaseSearch/CaseSearch'
import ExhibitSetReview from 'screens/ExhibitSetReview/ExhibitSetReview'
import ExhibitSet from 'screens/ExhibitSet/ExhibitSet'
import Invoice from 'screens/Invoice/Invoice'
import ServiceLinkRegister from 'screens/ServiceLinkRegister/ServiceLinkRegister'

function Router () {
  return (
    <Routes>
        <Route path={'/'} element={<Login />} />
        <Route path={'/login'} element={<Login />} />
        <Route path={'/register'} element={<ServiceLinkRegister />} />
        <Route path={'/servicelink-registration'} element={<ServiceLinkRegister />} />
        <Route path={'/forgot-password'} element={<ForgotPassword />} />
        <Route path={'/reset-password/:resetCode'} element={<ResetPassword />} />
        <Route path={'/account-setup'} element={<AccountSetup />} />
        <Route path={'/account-setup/:setupType'} element={<AccountSetup />} />
        <Route path={'/subscription-payment'} element={<SubscriptionPayment />} />
        <Route path={'/subscription-payment-preauth'} element={<SubscriptionPaymentPreauth />} />
        <Route path={'/verify-email'} element={<VerifyEmail />} />
        <Route path={'/document-set/:jobId/client-upload/:jobUploadCode'} element={<ClientPortal />} />
        <Route path={'clio-custom-action/:customActionType'} element={<ClioCustomAction />} />
        <Route path={'/app'} element={<App />}>
            <Route path={'cases'} element={<Cases />} />
            <Route path={'cases/:disputeId'} element={<Case />} />
            <Route path={'cases/:disputeId/:clioCustomAction'} element={<Case />}/>
            <Route path={'bates/:batesRecId'} element={<Outlet/>}>
                  <Route index element={<Bates />}/>
            </Route>
            <Route path={'document-set/:jobId'} element={<Job/>} />
            <Route path={'document-set/:jobId/classify-emails'} element={<ClassifyEmails />} />
            <Route path={'document-set/:jobId/document-review'} element={<DocumentReview />} />
            <Route path={'cases/:disputeId/case-search'} element={<CaseSearch />} />
            <Route path={'cases/:disputeId/exhibit-set/:exSetId'} element={<ExhibitSet />} />
            <Route path={'cases/:disputeId/exhibit-set/:exSetId/exhibit-set-review'} element={<ExhibitSetReview />} />
            <Route path={'clients'} element={<Clients />} />
            <Route path={'client/:clientId'} element={<Client />} />
            <Route path={'downloads'} element={<Downloads />} />
            <Route path={'preferences/:option'} element={<Preferences />} />
            <Route path={'preferences/:option/:redirect'} element={<Preferences />} />
            <Route path={'help'} element={<Help />} />
            <Route path={'help/:option'} element={<Help />} />
        </Route>
        <Route path='/add-to-clio-flow' element={<AddToClioFlow />} />
        <Route path='/identity-callback' element={<ClioIdentityCallback />} />
        <Route path='/cases/:disputeId/payments/:paymentId' element={<Invoice />} />
        <Route path='*' element={<NotFound />} />
    </Routes>
  )
}

export default Router
