import { Box, Button, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Typography } from 'shared-components/material/core'
import React, { useEffect, useState } from 'react'
import { useError } from 'shared-components/hooks'
import { getInvoiceData } from 'api/disputes'
import { ErrorModal } from 'shared-components/modals'
import { CenteredContent } from 'shared-components/layout'
import { useParams } from 'react-router-dom'
import { Cost, Dispute, Firm, Payment } from 'generated/graphql'
import './invoice.css'
import { currencyFormat } from 'shared-components/utils'
import moment from 'moment'
import validator from 'validator'

export default function Invoice() {
  const { disputeId, paymentId } = useParams()
  const [loading, setLoading] = useState(true)
  const [payment, setPayment] = useState<Partial<Payment>>({})
  const [costs, setCosts] = useState<Cost[]>([])
  const [firm, setFirm] = useState<Partial<Firm>>({})
  const [dispute, setDispute] = useState<Partial<Dispute>>({})
  const [userName, setUserName] = useState('')
  const [heading, setHeading] = useState('')
  const [type, setType] = useState(0)
  const [payMethodUiIdentifier, setPayMethodUiIdentifier] = useState('')
  const [error, href, handleError, resetError] = useError()
    
  useEffect(() => {
    const getInvoiceDataFn = async () => {
      const payload = await getInvoiceData(parseInt(disputeId as string), parseInt(paymentId as string))
        .catch((e) => handleError(e))
        console.log({payload})
      if (payload.success) {
        setHeading(payload.data.setHeading)
        setType(payload.data.setType)
        setPayment(payload.data.payment)
        setCosts(payload.data.costs)
        setFirm(payload.data.firm)
        setDispute(payload.data.dispute)
        setUserName(payload.data.userName)
        setPayMethodUiIdentifier(payload.data.payMethodUiIdentifier)
        setLoading(false)
      } else {
        setLoading(false)
        handleError(payload.err, payload.href)
      }
    }
    getInvoiceDataFn()
  }, [])

  if (loading) {
    return (
      <CenteredContent>
        <CircularProgress />
      </CenteredContent>
    )
  }

  return (
    <Box sx={{ width: '850px', height: '1100px', margin: '50px', boxSizing: 'border-box'}}>
      <Button className='printButton' variant='contained' color='secondary' sx={{ mb: 2}} onClick={() => window.print()}>
        Print
      </Button>
      <Box sx={{ width: '100%', height: '100%', border: '2px solid #444', boxSizing: 'border-box' }}>
        <Box sx={{ width: '100%', height: '45px', backgroundColor: '#444', boxSizing: 'border-box', p: '5px'}}>
          <Box sx={{ border: '2px solid #fff', display: 'inline-block', fontFamily: 'Gill Sans SemiBold', letterSpacing: '2px' }}>
            <span style={{ color: '#fff', fontSize: '1.5em', textTransform: 'uppercase', padding: '0 5px'}}>
              Discovery
            </span>
            <Box sx={{ backgroundColor: '#fff', display: 'inline-block', fontSize: '1.5em', textTransform: 'uppercase'}}>
              <span style={{ color:'#444', padding: '0 5px'}}>
                Genie<sup style={{fontSize: '10px'}}>&reg;</sup>
              </span>
            </Box>
          </Box>
        </Box>
        <Box sx={{ padding: '20px'}}>
          <Typography sx={{ mb: '32px'}} align='center'>
            Invoice {payment.paymentInvoiceNumber}
          </Typography>
          <Typography sx={{ mb: '64px'}}>
            {moment(payment.paymentPaidTimestamp).format('MM/DD/YYYY')}
          </Typography>
          <Typography gutterBottom>
            <strong>Invoice/Receipt to:</strong>
          </Typography>
          <Typography sx={{ mb: '64px'}}>
            {validator.unescape(firm.firmName as string)} <br/>
            Attention: {userName}<br/>
            {validator.unescape(firm.firmAddress1 as string)}<br/>
            {firm.firmAddress2 && <span>{validator.unescape(firm.firmAddress2 as string)}<br /></span>}
            {validator.unescape(firm.firmCity as string)}, {validator.unescape(firm.firmState as string)} {firm.firmZip}
          </Typography>
          <Typography sx={{ mb: '32px'}}>
            Client: {validator.unescape(userName as string)}<br/>
            Matter: {validator.unescape(dispute.disputeMatter as string)}<br/>
            Case: {validator.unescape(dispute.disputeName as string)} <br/>
            {validator.unescape(heading as string)}
          </Typography>
          <Typography gutterBottom>
            <strong>Documents processed:</strong>
          </Typography>
          {type === 2 ? (
            <Table sx={{ mb: '64px' }} size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>Category</TableCell>
                  <TableCell>Docs</TableCell>
                  <TableCell>Pages</TableCell>
                  <TableCell>Unit Cost</TableCell>
                  <TableCell>SubTotal</TableCell>
                </TableRow>
              </TableHead>
            <TableBody>
              {costs && costs.map((cost) => {
                const renderCategory = () => {
                  if (cost?.costType === 1) {
                    return 'Setup Charge'
                  }
                  if (cost?.costType === 2) {
                    return 'Upload Charge'
                  }
                  if (cost?.costType === 3) {
                    return 'Finish Charge'
                  }
                }
                const renderDocs = () => {
                  if (cost?.costType === 1) {
                    return ''
                  }
                  if (cost?.costType === 2) {
                    return cost?.costUploadDocsNum
                  }
                  if (cost?.costType === 3) {
                    return cost?.costStmpCount
                  }
                }
                const renderPages = () => {
                  if (cost?.costType === 1) {
                    return ''
                  }
                  if (cost?.costType === 2) {
                    return cost?.costUploadDocsPages
                  }
                  if (cost?.costType === 3) {
                    return cost?.costStmpPages
                  }
                }
                const renderUnitCost = () => {
                  if (cost?.costType === 1) {
                    return cost.costTotal
                  }
                  if (cost?.costType === 2) {
                    return cost.costPerUploadPage
                  }
                  if (cost?.costType === 3) {
                    return cost.costPerStmpPage
                  }
                }
                return (
                  <TableRow key={cost?.costId}>
                    <TableCell>{renderCategory()}</TableCell>
                    <TableCell>{renderDocs()}</TableCell>
                    <TableCell>{renderPages()}</TableCell>
                    <TableCell>{currencyFormat(renderUnitCost() as number)}</TableCell>
                    <TableCell>{currencyFormat(cost?.costTotalActual as number)}</TableCell>
                  </TableRow>
                )}
              )}
              <TableRow>
                <TableCell>TOTAL</TableCell>
                <TableCell/>
                <TableCell/>
                <TableCell/>
                <TableCell>{currencyFormat(payment.paymentGross as number)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          ) : (
            <Table sx={{ mb: '64px' }} size='small'>
            <TableHead>
              <TableRow>
                <TableCell>Category</TableCell>
                <TableCell>Docs</TableCell>
                <TableCell>Pages</TableCell>
                <TableCell>Unit Cost</TableCell>
                <TableCell>SubTotal</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {costs && costs[0]?.costPrivEmlsNum ? (
              <TableRow>
                <TableCell>Privilege Emails</TableCell>
                <TableCell>{costs && costs[0]?.costPrivEmlsNum}</TableCell>
                <TableCell>{costs && costs[0]?.costPrivEmlsPages}</TableCell>
                <TableCell>{costs && currencyFormat(costs[0]?.costPerDoc as number)}</TableCell>
                <TableCell>{costs && currencyFormat(costs[0]?.costPrivEmlsSubtotal as number)}</TableCell>
              </TableRow>
            ):null}
            {costs && costs[0]?.costPrivAttsNum ? (
              <TableRow>
                <TableCell>Privilege Attachments</TableCell>
                <TableCell>{costs && costs[0]?.costPrivAttsNum}</TableCell>
                <TableCell>{costs && costs[0]?.costPrivAttsPages}</TableCell>
                <TableCell>{costs && currencyFormat(costs[0]?.costPerDoc as number)}</TableCell>
                <TableCell>{costs && currencyFormat(costs[0]?.costPrivAttsSubtotal as number)}</TableCell>
              </TableRow>
            ):null}
            {costs && costs[0]?.costPrivDocsNum ? (
              <TableRow>
                <TableCell>Privilege Documents</TableCell>
                <TableCell>{costs && costs[0]?.costPrivDocsNum}</TableCell>
                <TableCell>{costs && costs[0]?.costPrivDocsPages}</TableCell>
                <TableCell>{costs && currencyFormat(costs[0]?.costPerDoc as number)}</TableCell>
                <TableCell>{costs && currencyFormat(costs[0]?.costPrivDocsSubtotal as number)}</TableCell>
              </TableRow>
            ):null}
            {costs && costs[0]?.costProdEmlsNum ? (
              <TableRow>
                <TableCell>Production Emails</TableCell>
                <TableCell>{costs && costs[0]?.costProdEmlsNum}</TableCell>
                <TableCell>{costs && costs[0]?.costProdEmlsPages}</TableCell>
                <TableCell>{costs && currencyFormat(costs[0]?.costPerDoc as number)}</TableCell>
                <TableCell>{costs && currencyFormat(costs[0]?.costProdEmlsSubtotal as number)}</TableCell>
              </TableRow>
            ):null}
            {costs && costs[0]?.costProdAttsNum ? (
              <TableRow>
                <TableCell>Production Attachments</TableCell>
                <TableCell>{costs && costs[0]?.costProdAttsNum}</TableCell>
                <TableCell>{costs && costs[0]?.costProdAttsPages}</TableCell>
                <TableCell>{costs && currencyFormat(costs[0]?.costPerDoc as number)}</TableCell>
                <TableCell>{costs && currencyFormat(costs[0]?.costProdAttsSubtotal as number)}</TableCell>
              </TableRow>
            ):null}
            {costs && costs[0]?.costProdDocsNum ? (
              <TableRow>
                <TableCell>Production Documents</TableCell>
                <TableCell>{costs && costs[0]?.costProdDocsNum}</TableCell>
                <TableCell>{costs && costs[0]?.costProdDocsPages}</TableCell>
                <TableCell>{costs && currencyFormat(costs[0]?.costPerDoc as number)}</TableCell>
                <TableCell>{costs && currencyFormat(costs[0]?.costProdDocsSubtotal as number)}</TableCell>
              </TableRow>
            ):null}
            {costs && costs[0]?.costIrrelEmlsNum ? (
              <TableRow>
                <TableCell>Irrelevant Emails</TableCell>
                <TableCell>{costs && costs[0]?.costIrrelEmlsNum}</TableCell>
                <TableCell>{costs && costs[0]?.costIrrelEmlsPages}</TableCell>
                <TableCell>{costs && currencyFormat(costs[0]?.costPerIrrel as number)}</TableCell>
                <TableCell>{costs && currencyFormat(costs[0]?.costIrrelEmlsSubtotal as number)}</TableCell>
              </TableRow>
            ):null}
              {costs && costs[0]?.costIrrelAttsNum ? (
                <TableRow>
                  <TableCell>Irrelevant Attachments</TableCell>
                  <TableCell>{costs && costs[0]?.costIrrelAttsNum}</TableCell>
                  <TableCell>{costs && costs[0]?.costIrrelAttsPages}</TableCell>
                  <TableCell>{costs && currencyFormat(costs[0]?.costPerIrrel as number)}</TableCell>
                  <TableCell>{costs && currencyFormat(costs[0]?.costIrrelAttsSubtotal as number)}</TableCell>
                </TableRow>
              ):null}
              {costs && costs[0]?.costIrrelDocsNum ? (
                <TableRow>
                  <TableCell>Irrelevant Documents</TableCell>
                  <TableCell>{costs && costs[0]?.costIrrelDocsNum}</TableCell>
                  <TableCell>{costs && costs[0]?.costIrrelDocsPages}</TableCell>
                  <TableCell>{costs && currencyFormat(costs[0]?.costPerIrrel as number)}</TableCell>
                  <TableCell>{costs && currencyFormat(costs[0]?.costIrrelDocsSubtotal as number)}</TableCell>
                </TableRow>
              ):null}
              {costs && costs[0]?.costHoldEmlsNum ? (
                <TableRow>
                  <TableCell>Hold Emails</TableCell>
                  <TableCell>{costs && costs[0]?.costHoldEmlsNum}</TableCell>
                  <TableCell>{costs && costs[0]?.costHoldEmlsPages}</TableCell>
                  <TableCell>{costs && currencyFormat(costs[0]?.costPerHold as number)}</TableCell>
                  <TableCell>{costs && currencyFormat(costs[0]?.costHoldEmlsSubtotal as number)}</TableCell>
                </TableRow>
              ):null}
              {costs && costs[0]?.costHoldAttsNum ? (
                <TableRow>
                  <TableCell>Hold Attachments</TableCell>
                  <TableCell>{costs && costs[0]?.costHoldAttsNum}</TableCell>
                  <TableCell>{costs && costs[0]?.costHoldAttsPages}</TableCell>
                  <TableCell>{costs && currencyFormat(costs[0]?.costPerHold as number)}</TableCell>
                  <TableCell>{costs && currencyFormat(costs[0]?.costHoldAttsSubtotal as number)}</TableCell>
                </TableRow>
              ):null}
              {costs && costs[0]?.costHoldDocsNum ? (
                <TableRow>
                  <TableCell>Hold Documents</TableCell>
                  <TableCell>{costs && costs[0]?.costHoldDocsNum}</TableCell>
                  <TableCell>{costs && costs[0]?.costHoldDocsPages}</TableCell>
                  <TableCell>{costs && currencyFormat(costs[0]?.costPerHold as number)}</TableCell>
                  <TableCell>{costs && currencyFormat(costs[0]?.costHoldDocsSubtotal as number)}</TableCell>
                </TableRow>
              ):null}
              <TableRow>
                <TableCell>TOTAL</TableCell>
                <TableCell/>
                <TableCell/>
                <TableCell/>
                <TableCell>{currencyFormat(payment.paymentGross as number)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          )}
          {payment.paymentGross && payment.paymentGross > 0 ? (
            <Typography>
              Charged to Card No. xxxx-xxxx-xxxx-{payMethodUiIdentifier}
            </Typography>
          ): null}
        </Box>
      </Box>
      <ErrorModal error={error} href={href} resetError={resetError} />
    </Box>
  )
}
