import React, { useEffect, useState } from 'react'
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"

import { CenteredForm, SplitScreen } from 'shared-components/layout'
import { Button, Typography, Divider, Link, Box } from 'shared-components/material/core'
import { TextInput, PasswordInput, CheckboxInput, TelephoneInput } from 'shared-components/inputs'
import { RegisterSVG } from 'shared-components/svg'
import { getPartner, register } from 'api/accounts'
import { useError } from 'shared-components/hooks'
import { ErrorModal } from 'shared-components/modals'
import { useLocation } from 'react-router-dom'
import ExhibitGenieIntro from 'shared-components/videos/ExhibitGenieIntro'

const schema = yup.object({
    firstname: yup.string().required('First name required'),
    lastname: yup.string().required('Last name required'),
    firmTelephone: yup
        .string()
        .required('Firm telephone is required'),
    firmName: yup.string().required('Firm name required'),
    email: yup.string().email("please enter a valid email").required('Email required'),
    password: yup
        .string()
        .required()
        .matches(
            // eslint-disable-next-line no-useless-escape
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            ),
    confirmPassword: yup
        .string()
        .when("password", {
            is: (val: string) => (val && val.length > 0 ? true : false),
            then: yup.string().oneOf(
                [yup.ref("password")],
                "Passwords must match"
            ),
        })
        .required("Confirm Password Required"),
    termsChecked: yup.bool().oneOf([true], 'Please read and accept the user agreement and terms of use.')
  }).required()

type FormData = {
    firstname: string,
    lastname: string,
    firmName: string,
    firmTelephone: string,
    email: string,
    password: string,
    confirmPassword: string,
    termsChecked: boolean,
}

const Copy = () => {
  return (
    <Box sx={{ maxWidth: '625px', margin: '0 auto 50px auto' }}>  
        <Typography variant="body1" component="p" textAlign='left' gutterBottom>
            ServiceLink has partnered with Discovery Genie to bring you Exhibit Genie, a platform that 
            transforms the chaos of exhibit management to calm productivity.
        </Typography>
        <Typography variant="body1" component="p" textAlign='left'>
            What to do next:
        </Typography>
        <Typography>
            <ul>
                <li>Sign up for a free Discovery Genie account. (Log in if you already have an account.)</li>
                <li>Follow Exhibit Genie’s four simple steps to easy, fast and stress-free exhibit 
                management:</li>
                <ol>
                    <li>Upload a .zip file of your exhibits.</li>
                    <li>Order the exhibits using Exhibit Genie’s intuitive click-and-drag interface. Fine-tune the dates, descriptions and Bates numbers for your exhibit list.</li>
                    <li>Click “finish” to create a ready-to-file Exhibit List, plus print-ready exhibit files, 
                    properly named, stamped and page numbered.</li>
                    <li>Click “Order Print.”</li>
                </ol>
                <li>Your completed exhibit set will be printed and shipped, per your instructions.</li>
            </ul>
        </Typography>
    </Box>
  )
}


function ServiceLinkRegister() {
    const { search } = useLocation()
    const partner = new URLSearchParams(search).get('partner')
    const [confirmation, setConfirmation] = useState(false)
    const [partnerId, setPartnerId] = useState(null)
    const [error, href, handleError, resetError ] = useError()

    const getPartnerFn = async () => {
        const payload = partner && (await getPartner(partner)
            .catch(e => handleError(e)))
        if (payload.success) {
            setPartnerId(payload.data.partner.partnerId)
        } else {
            handleError(payload.err, payload.href)
        }
    }

    useEffect(() => {
        if (partner) {
            getPartnerFn()
        }
    }, [partner])

    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
            firstname: '',
            lastname: '',
            firmName: '',
            firmTelephone: '',
            email: '',
            password: '',
            confirmPassword: '',
            termsChecked: false,
        },
        resolver: yupResolver(schema),
    })

    const { handleSubmit, formState: { errors }} = methods

    const onSubmit = async (data: FormData) => {
        const reg = {...data, partnerId}
        const payload = await register(reg) 
            .catch(e => handleError(e))
        if (payload.success) {
            setConfirmation(true)
        } else {
            handleError(payload.err, payload.href)
        }
    }

    if (confirmation) {
        return (
            <SplitScreen svg={<RegisterSVG width="75%" />}>
                <CenteredForm>
                    <Typography variant="body1" component="p" textAlign='center'>
                        You have registered successfuly!
                    </Typography>
                    <Typography variant="body1" component="p" textAlign='center'>
                        Please validate your email address by clicking the link in the email we sent you. 
                    </Typography>
                    <Button variant="text" size="small" href="/login">Return to Login</Button>
                </CenteredForm>
            </SplitScreen>
        )
    }

    return (
        <FormProvider {...methods} >
            <SplitScreen video={<ExhibitGenieIntro />} copy={<Copy />}>
                    <img
                        width='336.73px'
                        src='/blueLogo.png'
                        style={{ alignSelf: 'center', marginBottom: '2em' }}
                    />
                    <img
                        width='336.73px'
                        src='https://dxd9z2jiqfgtz.cloudfront.net/images/servicelink_logo.png'
                        style={{ alignSelf: 'center', marginBottom: '2em' }}
                    />   
                    <CenteredForm>
                    <Typography variant="h2" component="h2" textAlign='left' gutterBottom>
                        Register
                    </Typography>
                    <TextInput
                        name="firstname"
                        label="First Name"
                        required
                        error={errors.firstname !== undefined ? true : false }
                        errorMessage={errors.firstname ? errors.firstname.message : undefined}
                    />
                    <TextInput
                        name="lastname"
                        label="Last Name"
                        required
                        error={errors.lastname !== undefined ? true : false }
                        errorMessage={errors.lastname ? errors.lastname.message : undefined}
                    />
                    <TextInput
                        name="firmName"
                        label="Firm Name"
                        required
                        error={errors.firmName !== undefined ? true : false }
                        errorMessage={errors.firmName ? errors.firmName.message : undefined}
                    />
                    <TelephoneInput
                        name="firmTelephone"
                        label="Firm Telephone"
                        required
                        error={errors.firmTelephone !== undefined ? true : false }
                        errorMessage={errors.firmTelephone ? errors.firmTelephone.message : undefined}
                    />
                    <TextInput
                        name="email"
                        label="E-Mail"
                        required
                        error={errors.email !== undefined ? true : false }
                        errorMessage={errors.email ? errors.email.message : undefined}
                    />
                    <PasswordInput
                        name={"password"}
                        label={"Password"}
                        error={errors.password != undefined ? true : false }
                        errorMessage={errors.password ? errors.password.message : undefined}
                    />
                    <PasswordInput
                        name={"confirmPassword"}
                        label={"Confirm Password"}
                        error={errors.confirmPassword != undefined ? true : false }
                        errorMessage={errors.confirmPassword ? errors.confirmPassword.message : undefined}
                    />
                    <CheckboxInput 
                        name={"termsChecked"}
                        label={
                            <Typography variant="body1" component="p" textAlign='left'>
                                I have read and agree to the{' '}
                                <Link 
                                    href="http://www.discoverygenie.com/wp-content/uploads/2023/10/2023-Terms-of-Use-v.7.pdf" 
                                    target="_blank"
                                >
                                    user agreement and terms of use
                                </Link>.
                            </Typography>   
                        }
                        required
                        error={errors.termsChecked != undefined ? true : false }
                        errorMessage={errors.termsChecked ? errors.termsChecked.message : undefined}
                    />
                    <Button
                        variant="contained"
                        size="large"
                        onClick={handleSubmit(onSubmit)}
                    >
                        Register
                    </Button>
                    <Divider sx={{ m: '1rem' }} />
                    <Typography variant="body1" component="p" textAlign='center'>
                        Already have an account?
                    </Typography>
                    <Button variant="text" size="small" href="/login">Login</Button>
                </CenteredForm>
            </SplitScreen>
            <ErrorModal error={error} href={href} resetError={resetError} />
        </FormProvider>
    )
}

export default ServiceLinkRegister
